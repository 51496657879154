import React, { useContext, useEffect } from 'react';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// import PropTypes from "prop-types";
import styled from 'styled-components';

import Map from 'ToolboxComponents/commons/map/map';

const SearchWrapper = styled.div`
  .places-autocomplete {
    position: relative;
    z-index: 10000;
  }

  .places-autocomplete-list {
    position: absolute;
    width: 260px;
  }

  .places-autocomplete-list-element {
    padding: 5px;
  }
`;

const Search = props => {
  const whiteLabel= useContext(WhiteLabelContext).state;
  const { tertiaryColor } = useContext(ThemeContext).state.color;

  const {
    locationValue,
    coordinatesValue,
    handleSearch,
    handleCoordinates,
    handleCityCode,
    locationValid,
    hasClickedSubmit,
    userLocation,
    isProduct,
  } = props;

  useEffect(() => {
    if (
      locationValue !== '' &&
      locationValue !== null &&
      locationValue !== undefined
    ) {
      handleSelect(locationValue);
    }
  }, []);

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const cityCode = await getCityCode(latLng);
    handleCityCode(cityCode);
    handleCoordinates({ coordinates: latLng, isProduct: isProduct});
    handleSearch(value);
  };

  const getCityCode = async ({ lat, lng }) => {
    const data = await fetch(
      `https://geo.api.gouv.fr/communes?fields=nom,code,codeRegion&lon=${lng}&lat=${lat}`,
    );
    const response = await data.json();
    return response !== undefined ? response[0].code : null;
  };

  return (
    <SearchWrapper>
      <PlacesAutocomplete
        value={locationValue}
        onChange={handleSearch}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: ['fr'] },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={`places-autocomplete`}>
            <input
              {...getInputProps({
                placeholder: (whiteLabel.textualContent && whiteLabel.textualContent.page && whiteLabel.textualContent.page.search.input) || 'Saisissez un lieu',
                className: `input ${
                  hasClickedSubmit === true && locationValid === false
                    ? 'error'
                    : ''
                }`,
              })}
            />
            <div className={`places-autocomplete-list`}>
              {/*loading === true && <div>loading...</div>*/}
              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? tertiaryColor : '#fff',
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                      className: 'places-autocomplete-list-element',
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <hr className={`separator`} />
      <Map
        className={`map`}
        height={`200px`}
        userLocation={userLocation}
        itemLocations={coordinatesValue}
        selected={0}
        zoom={16}
      />
    </SearchWrapper>
  );
};

// Search.propTypes = {};

export default Search;
