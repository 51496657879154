import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker, GeoJSON } from 'react-leaflet';
import MapWrapper from './map.style';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import { UserIcon, MarkerIcon, MiniIcon } from './icons/icons';
import tj from '@mapbox/togeojson';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';

const MapSimple = props => {
  const { color } = useContext(ThemeContext).state;
  const [isInZone] = useContext(WhiteLabelContext).usePath('isInZone');
  const apiServerCors = process.env.CONFIG.API_CORS;

  const fill = props.fill !== undefined ? props.fill : color.primaryColor;
  const fillOff = props.fillOff !== undefined ? props.fillOff : color.secondaryColor;
  const { userLocation = {}, itemLocations, clickLocation, cluster, initCenter, pageVisible } = props;

  const miniMarkersSpan = Math.floor(props.miniMarkersSpan / 2);

  const [center, setCenter] = useState(
    initCenter !== undefined
      ? initCenter
      : userLocation.latitude
      ? [userLocation.latitude, userLocation.longitude]
      : itemLocations.length > 0
      ? [itemLocations[0].latitude, itemLocations[0].longitude]
      : [0, 0],
  );
  const [zoom, setZoom] = useState(props.zoom);
  const [selected, setSelected] = useState(props.selected);
  const [geoJSON, setgeoJSON] = useState(null);
  const [gpx, setGpx] = useState(null);
  const [kml, setKml] = useState(null);
  const [itemLocationsCount, setItemLocationsCount] = useState(0);
  const [didMount, setDidMount] = useState(false);
  const [pageNumber, setPageNumber] = useState(pageVisible ? pageVisible : null);

  const mapRef = useRef(null);

  //isMobile
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  useEffect(() => {
    setPageNumber(pageVisible);
  }, [pageVisible]);

  //get trails
  useEffect(() => {
    async function fetchGeoJSON() {
      const response = await fetch(`${apiServerCors}/?url=${props.geoJSON}`);
      const data = await response.json();
      setgeoJSON(data);
    }
    async function fetchGPX() {
      const response = await fetch(`${apiServerCors}/?url=${props.gpx}`);
      const textString = await response.text();
      const data = await new DOMParser().parseFromString(
        textString,
        'text/xml',
      );
      setGpx(tj.gpx(data));
    }
    async function fetchKML() {
      const response = await fetch(`${apiServerCors}/?url=${props.kml}`);
      const textString = await response.text();
      const data = await new DOMParser().parseFromString(
        textString,
        'text/xml',
      );
      setKml(tj.kml(data));
    }

    if (props.geoJSON !== null) {
      fetchGeoJSON();
    }
    if (props.gpx !== null) {
      fetchGPX();
    }
    if (props.kml !== null) {
      fetchKML();
    }
    // eslint-disable-next-line
  }, []);

  //when add tag filters
  useEffect(() => {
    if ((itemLocationsCount !== itemLocations.length && itemLocations.length > 1) || (pageNumber && pageVisible && pageNumber !== pageVisible)) {
      boundCalculator(itemLocations);
      setItemLocationsCount(itemLocations.length);
    }

    if (itemLocations.length === 1) {
      mapRef.current.leafletElement.setView(
        [itemLocations[0].latitude, itemLocations[0].longitude],
        props.zoom,
      );
    }
    // eslint-disable-next-line
  }, [itemLocations]);

  //slider swipe
  useEffect(() => {
    if ((isMobile === true || didMount === true) && itemLocations[props.selected]) {
      let previousMarkers, followingMarkers;

      switch (props.selected) {
        case 0:
          previousMarkers = 0;
          followingMarkers = 4;
          break;
        case 1:
          previousMarkers = 1;
          followingMarkers = 3;
          break;
        case itemLocations.length - 2:
          previousMarkers = 3;
          followingMarkers = 1;
          break;
        case itemLocations.length - 1:
          previousMarkers = 4;
          followingMarkers = 0;
          break;
        default:
          previousMarkers = 2;
          followingMarkers = 2;
      }

      // if (props.selected !== 0) {
      // boundCalculator(
      //   // itemLocations.slice(
      //   //   props.selected - previousMarkers,
      //   //   props.selected + followingMarkers,
      //   // ),
      //   itemLocations,
      // );
      // // }
      setSelected(props.selected);
      // setCenter([
      //   itemLocations[props.selected].latitude,
      //   itemLocations[props.selected].longitude,
      // ]);
    }
    setDidMount(true);
    // eslint-disable-next-line
  }, [props.selected]);

  useEffect(() => {
    if (itemLocations.length > 0) {
      boundCalculator(itemLocations);
      setItemLocationsCount(itemLocations.length);
    }
  }, [props.productsList]);

  const boundCalculator = listLocations => {
    const allMarkersCoordinates = listLocations.map(item => [
      item.latitude,
      item.longitude,
    ]);
    const allMarkersLatitudes = allMarkersCoordinates.map(item => item[0]);
    const allMarkersLongitudes = allMarkersCoordinates.map(item => item[1]);
    // if isInZone add user position to extrem list
    if (isInZone === true && userLocation.latitude !== null) {
      allMarkersLatitudes.push(userLocation.latitude);
      allMarkersLongitudes.push(userLocation.longitude);
    }
    allMarkersLatitudes.sort((a, b) => a - b);
    allMarkersLongitudes.sort((a, b) => a - b);
    const extremMarkersLatitudes = [
      allMarkersLatitudes[0],
      allMarkersLatitudes[allMarkersLatitudes.length - 1],
    ];
    const extremMarkersLongitudes = [
      allMarkersLongitudes[0],
      allMarkersLongitudes[allMarkersLongitudes.length - 1],
    ];
    // if (listLocations.length > 0) {
    //   if (userLocation.longitude !== null) {
    //     let lowerLatitude = extremMarkersLatitudes[0];
    //     let lowerLongitude = extremMarkersLongitudes[0];
    //     let higherLatitude = extremMarkersLatitudes[1];
    //     let higherLongitude = extremMarkersLongitudes[1];
    //
    //     if (userLocation.latitude < extremMarkersLatitudes[0]) {
    //       lowerLatitude = userLocation.latitude;
    //     }
    //     if (userLocation.latitude > extremMarkersLatitudes[1]) {
    //       higherLatitude = userLocation.latitude;
    //     }
    //     if (userLocation.longitude < extremMarkersLongitudes[0]) {
    //       lowerLongitude = userLocation.longitude;
    //     }
    //     if (userLocation.longitude > extremMarkersLongitudes[1]) {
    //       higherLongitude = userLocation.longitude;
    //     }
    //
    //     mapRef.current.leafletElement.fitBounds([
    //       [lowerLatitude, lowerLongitude],
    //       [higherLatitude, higherLongitude],
    //     ]);
    //   } else {
    mapRef.current.leafletElement.fitBounds([
      [extremMarkersLatitudes[0], extremMarkersLongitudes[0]],
      [extremMarkersLatitudes[1], extremMarkersLongitudes[1]],
    ]);
    // }}
  };

  const updateSelected = id => {
    setSelected(id);
    if (clickLocation !== undefined) {
      clickLocation(id);
    }
    // setCenter([itemLocations[id].latitude, itemLocations[id].longitude]);
  };

  const switchIcon = id => {
    if (props.miniMarkers === true) {
      if (id === selected) {
        return MarkerIcon(fill, 100000000);
      } else if (
        id >= selected - miniMarkersSpan &&
        id <= selected + miniMarkersSpan
      ) {
        return MarkerIcon(fillOff);
      } else {
        return MiniIcon(fill);
      }
    } else {
      return id === selected
        ? MarkerIcon(fill, 100000000)
        : MarkerIcon(fillOff);
    }
  };

  const displayMarkers = () => (
    <>
      {itemLocations.map((item, index) => (
        <Marker
          key={index}
          id={index}
          icon={switchIcon(index)}
          position={[item.latitude, item.longitude]}
          onClick={event => updateSelected(event.target.options.id)}
        />
      ))}
    </>
  );

  const displayUserLocationMarker = () => (
    <>
      {userLocation.latitude !== null && (
        <Marker
          icon={UserIcon(fill)}
          position={[userLocation.latitude, userLocation.longitude]}
          zIndexOffset={10000}
        />
      )}
    </>
  );

  return (
    <MapWrapper {...props}>
      <Map
        ref={mapRef}
        center={center}
        style={{ height: props.height, width: props.width }}
        className="markercluster-map"
        zoom={zoom}
        onZoomEnd={() => setZoom(mapRef.current.leafletElement.getZoom())}
        maxZoom={18}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {cluster === true ? (
          <>
            <MarkerClusterGroup
              maxClusterRadius={
                itemLocations.length > 10 ? (isMobile === true ? 30 : 80) : 0
              }
            >
              {displayMarkers()}
            </MarkerClusterGroup>
            {displayUserLocationMarker()}
          </>
        ) : (
          <>
            {displayMarkers()}
            {displayUserLocationMarker()}
          </>
        )}

        {geoJSON !== null && <GeoJSON data={geoJSON} />}
        {gpx !== null && <GeoJSON data={gpx} />}
        {kml !== null && <GeoJSON data={kml} />}
      </Map>
    </MapWrapper>
  );
};

MapSimple.defaultProps = {
  userLocation: { latitude: null, longitude: null }, //user location
  itemLocations: [], //coordinates of all products
  clickLocation: undefined, //onClick callback
  miniMarkers: false, //show small markers if far from selected marker
  miniMarkersSpan: 5, //how big is the group of markers NOT minified around selected marker
  cluster: false, //clusterize the itemLocations markers
  selected: null, //selected product id
  height: '100%', //map height
  width: '100%', //map width
  zoom: 11, //level of zoom on the map
  geoJSON: null, //geoJSON file
  gpx: null, //gpx file
  kml: null, //kml file
  theme: {}, //how the component should display
  markers: {
    //control used markers and custom colors
    user: {
      icon: 'user',
      color: 'theme',
    },
    global: {
      icon: 'marker',
      color: 'theme',
    },
    selected: {
      icon: 'marker-selected',
      color: 'theme',
    },
    small: {
      icon: 'mini',
      color: 'theme',
    },
  },
};

MapSimple.propTypes = {
  userLocation: PropTypes.object,
  itemLocations: PropTypes.array,
  clickLocation: PropTypes.func,
  miniMarkers: PropTypes.bool,
  miniMarkersSpan: PropTypes.number,
  cluster: PropTypes.bool,
  selected: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
  zoom: PropTypes.number,
  geoJSON: PropTypes.string,
  theme: PropTypes.object,
  markers: PropTypes.object,
  fill: PropTypes.string, //active marker's color
  fillOff: PropTypes.string, //inactive markers' color
  initCenter: PropTypes.array, //set the initial center of the map
  pageVisible: PropTypes.number, //set the initial center of the map
};

export default MapSimple;
