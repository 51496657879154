import React from 'react';
// import PropTypes from "prop-types";
import UploadSecretImage from 'ToolboxComponents/webapp/modals/modal-secret/components/upload-secret-image';

import styled from 'styled-components';

const SecretDetailsWrapper = styled.div`
  position: relative;

  .button-submit {
    position: relative;
    z-index: 1;
  }

  .message {
    cursor: default;
    position: absolute;
    width: 100%;
    height: 38px;
    bottom: 0;
    overflow: hidden;
    transition: bottom 0.3s, height 0.3s;

    &.show {
      bottom: 55px;
      height: auto;
    }

    &.warning {
      background: #ff851b;
    }

    &.error {
    }
  }
`;

const SecretDetails = props => {
  const {
    titleValue,
    textareaValue,
    photoValue,
    handleTitle,
    handleTextarea,
    handlePhoto,
    textareaValid,
    submitValid,
    hasClickedSubmit,
    handleSubmit,
    hasSecret,
    errorOnSecretSave,
    submitNotValidMessage,
    onButtonSubmitClicked,
  } = props;

  return (
    <SecretDetailsWrapper>
      <div className={`title`}>Titre de votre secret à chuchoter</div>
      <input
        type={`text`}
        className={`input`}
        value={titleValue}
        placeholder={`Saisissez ici le titre de votre secret`}
        onChange={event => handleTitle(event.target.value)}
      />
      <div className={`title`}>Détail de votre secret</div>
      <textarea
        className={`input textarea ${
          hasClickedSubmit === true && textareaValid === false ? 'error' : ''
        }`}
        value={textareaValue}
        placeholder={`Saisissez ici le contenu de votre secret local.

Petit rappel :
- on ne chuchote jamais un secret sur sa propre structure
- on partage ce qu'on aime dans les détails sur ce lieu... un prénom, un produit bien précis, un conseil pratique, ce petit plus que vous adorez.`}
        onChange={event => handleTextarea(event.target.value)}
      ></textarea>
      <div className={`nb-characters`}>
        {textareaValue.length}/100 caractères minimum
      </div>
      <div className={`upload`}>
        <div className={`title`}>Une photo pour illustrer votre secret ?</div>
        <div className={`title-small`}>(facultatif)</div>
        <UploadSecretImage
          photoValue={photoValue}
          handlePhoto={value => handlePhoto(value)}
        />
      </div>
      <div
        className={`button button-submit ${
          submitValid === false ? 'disabled' : ''
        }`}
        onClick={() => handleSubmit()}
      >
        {hasSecret === true
          ? ' enregistrer les modifications '
          : ' soumettre votre secret à notre équipe '}
      </div>
      <div
        className={`button warning message ${
          onButtonSubmitClicked === true && submitNotValidMessage.length > 0
            ? 'show'
            : ''
        }`}
      >
        {submitNotValidMessage}
      </div>
      <div
        className={`button error message ${errorOnSecretSave === true ? 'show' : ''}`}
      >
        Un problème est survenu, la sauvegarde du secret a échoué !
      </div>
    </SecretDetailsWrapper>
  );
};

// SecretDetails.propTypes = {};

export default SecretDetails;
