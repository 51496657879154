import React from 'react';
import styled from 'styled-components';

const SingleUploadImageWrapper = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ListUploadedNames = props => {
  return (
    <SingleUploadImageWrapper className={props.className}>
      {props.uploadedFiles
        ? props.uploadedFiles.map(file => (
            <img src={`${file}`} alt="" />
          ))
        : props.photoValue !== undefined && (
            <img src={props.photoValue} alt="" />
          )}
    </SingleUploadImageWrapper>
  );
};

export default ListUploadedNames;
